import React, { useState, useEffect } from 'react'
import { Container, Form, Button, Card } from 'react-bootstrap'
import Spinner from '../../common/Spinner';
import {baseUrl} from '../../../config/baseUrl';
import { decodeHtml } from '../../../config/helper';
import FailurePage from '../../earth-gateway/FailurePage'
import { Link } from 'react-router-dom';
import GpayApayContainer from '../../checkout/GpayApayContainer';
import { goBackButton, getDecodedData } from '../../../helpers';

export default function CheckoutSaveCard({ encryptedData, base64Data }) {
    // let BackendUrl = 'http://localhost:4007/dev/api/v1/sale/hosted?data='
    let BackendUrl = baseUrl.checkout + '/v1/sale/hosted?data=';
    const [cvv, setCvv] = useState('')
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [token, setToken] = useState(null);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState('');
    const [failedMessage, setFailedMessage] = useState('');

    const onFormSubmit = (e) => {
        if (e) {
            e.preventDefault();
            console.log("onFormSubmit", e.target.cvc.value);
            setCvv(e.target.cvc.value);
            setLoading(true);
        }
        let url = BackendUrl + encryptedData + `&response=${base64Data}`;
        // check if cvv_exists for express 3DS redirection sale
        if (!data?.cvv_exists) {
            // add cvv data for normal saved card
            url += `&v=${cvv}`;
        }
        window.location.replace(url);
    }

    useEffect(() => {
        setLoading(true);
        const decryptData = async () => {
            let { token, decodedString } = await getDecodedData(base64Data);
            console.log('decodedString', decodedString);
            if (decodedString?.cvv_exists) {
                // straight away call BE API to process sale without asking for CVV value
                onFormSubmit();
            } else {
                setToken(token[0]);
                setData(decodedString);
                setLoading(false);
            }
        }
        base64Data && decryptData();
    }, [base64Data]);

    const allowOnlyNumber = (value) => {
        const re = /^[0-9\b]+$/
        if (value === '' || re.test(value)) {
            return true
        }
        return false
    }

    return !error ? (
        loading ? <Spinner message='Please wait redirecting to 3ds' /> :
            <>
                <Container className='mt-4 col d-flex justify-content-center'>
                    <div className='col-sm-6 col-md-6 col-lg-5 col-xl-4'>
                        <div className='back-btn-container'>
                            <Link
                                style={{ fontSize: '12px', fontWeight: 500, paddingLeft: 0, paddingTop: 0 }}
                                to="#" className='nav-link text-left'>
                                
                            </Link>
                            <div className="btn btn-light" onClick={() => goBackButton(`/checkout/pay?data=${encryptedData}&response=${base64Data}=&via=1`)}>« Back</div>
                        </div>
                        <GpayApayContainer data={data} encryptData={encryptedData} base64Data={base64Data} setError={setError} setMessage={setMessage} setFailedMessage={setFailedMessage} setLoading={setLoading} />
                        <Card>
                            <Card.Body>
                                <Form onSubmit={onFormSubmit}>
                                    <p className='lead'>
                                        Card Ending With {token?.last_four_digits}
                                    </p>
                                    <small>
                                        Please enter your CVV.
                                    </small>

                                    <Form.Group>
                                        <Form.Label className='float-left'>CVV </Form.Label>
                                        <Form.Control
                                            inputMode='numeric'
                                            type='tel'
                                            name='cvc'
                                            autoComplete='cc-csc'
                                            autoFocus="on"
                                            placeholder='CVV'
                                            className='shaow-sm  form-control '
                                            pattern='[0-9]*'
                                            value={cvv}
                                            minLength={3}
                                            maxLength={4}
                                            required
                                            onChange={(event) => {
                                                const value = event.target.value
                                                if (allowOnlyNumber(value)) setCvv(value)
                                            }}
                                        />
                                    </Form.Group>
                                    <Button
                                        className='btn btn-primary btn-block'
                                        value='proceed'
                                        type='submit'
                                        disabled={!(cvv.length >= 3)}
                                    >
                                        Pay &nbsp;{decodeHtml(data.currency_sign ? data.currency_sign : data.country_info.currency_sign)} {parseFloat(data.total).toFixed(2)}
                                    </Button>
                                </Form>
                            </Card.Body>

                        </Card>
                    </div>
                </Container>
            </>
    ) : (
        <FailurePage
          encryptData={encryptedData}
          message={message}
          base64Data={base64Data}
          dataDecode={data}
          failedMessage={failedMessage}
        />
      )
}
