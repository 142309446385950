import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { Card, Container } from 'react-bootstrap';
import { initiatePayment } from "./initiate-payment";
import { handleRedirect } from "./handle-redirect";
import Spinner from '../common/Spinner';
import { getDecodedData, goBackButton } from '../../helpers';
import './style.css';

export default function AdyenCheckoutPage(props) {

    //console.log('baseUrl',baseUrl);return;
    const [message, setMessage] = useState('Processing payment...');
    const [loading, setLoading] = useState(false);
    const [failure, setFailure] = useState(false);
    const [decryptedData, setDecryptedData] = useState(null);
    const [phonePaymentMode, setPhonePaymentMode] = useState(false);

    // get encryptedData from query params
    const url = window.location.href;
    const params = queryString.parseUrl(url);
    const encryptedData = params.query.data;
    const base64Data = params.query.response;

    // get sessionId & redirectResult for adyen redirect
    const queryResultString = window.location.search;
    const urlParams = new URLSearchParams(queryResultString);
    const redirectResult = urlParams.get('redirectResult');
    const sessionId = urlParams.get('sessionId');

    useEffect(() => {
        const checkMotoPayment = async () => {
            const { decodedString } = await getDecodedData(base64Data);
            setDecryptedData(decodedString);
            if(decodedString?.phone_payment) {
                setPhonePaymentMode(true);
            }
        } 
        base64Data && checkMotoPayment();
    }, [base64Data]);

    useEffect(() => {
        // If no paramters are present in the URL, mount the Drop-in
        if (!redirectResult && !sessionId) {
            initiatePayment(encryptedData, base64Data, { setLoading, setMessage, setFailure });
        } else {
            // Otherwise, handle the redirect
            handleRedirect(sessionId, redirectResult, { setLoading, setMessage, setFailure });
        }
    }, [sessionId, redirectResult, encryptedData, base64Data]);

    return !failure ? (!loading ? <Spinner message={message} /> :
        <div className="payment-container">
            { !phonePaymentMode && <div className="back-btn-container btn btn-light" onClick={() => goBackButton(decryptedData?.back_url)}>« Back</div> }
            <div id="dropin-container"></div>
        </div>
    )
        :
        (<div className="payment-container">
            { !phonePaymentMode && <div className="back-btn-container btn btn-light" onClick={() => goBackButton(`/antar?data=${encryptedData}&response=${base64Data}`)}>« Back</div> }
            <Card className="text-center border-danger  alert-danger ">
                <Card.Body><div className='text-danger' style={{ fontSize: '18px' }}>{message}</div></Card.Body>
            </Card>
        </div>)
}
