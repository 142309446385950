import React, { useState, useEffect } from 'react'
import {Buffer} from 'buffer';
import queryString from 'query-string'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {baseUrl} from '../../../config/baseUrl'
import axios from 'axios'
import CardList from '../../common/Card/CardList'
import Spinner from '../../common/Spinner'
import FailurePage from '../FailurePage'
import CheckoutForm from '../CheckoutForm/CheckoutForm'
import PhonePaymentCheckoutForm from '../PhonePaymentCheckout/PhonePCheckoutForm';
import { provider_types } from '../../../utils/enums';
import { helpers, goBackButton, getDecodedData, initiateSale, deviceInfo } from '../../../helpers';
import GpayApayContainer from '../../checkout/GpayApayContainer';

const SavedCC = () => {
  const [encryptedData, setEncryptedData] = useState('')
  const [base64Data, setBase64Response] = useState('')
  const [data, setData] = useState({})
  const [message, setMessage] = useState('')
  const [failedMessage, setFailedMessage] = useState('')
  const [token, setToken] = useState([])
  const [loading, setLoading] = useState(true);
  const [failure, setFailure] = useState(false)
  const [paid, setPaid] = useState(false);
  const [exist, setExist] = useState(false);
  const [cancelUrl, setCancelUrl] = useState('')
  const [phonePMode, setPhonePaymentMode] = useState(false);
  const [providerName, setProviderName] = useState("");
  const [decodedData, setDecodedData] = useState('');
  const [decodedToken, setdecodedToken] = useState('');

  const renderCard = token.map((_token, i) => {
    return (
      <CardList
        token={_token}
        data={data}
        key={i}
        encryptData={encryptedData}
        base64Data={base64Data}
        provider={provider_types.EARTH}
      />
    )
  });

  const decryptBase64Data = async (response) => {
    let { token, decodedString } = await getDecodedData(response);
    setDecodedData(decodedString);
    setdecodedToken(token[0]);
  }
 
  useEffect(() => {
    const url = window.location.href
    const params = queryString.parseUrl(url)

    const encryptedData = params.query.data
    const base64EncryptedData = params.query.response
    decryptBase64Data(base64EncryptedData);
    setEncryptedData(encryptedData)
    setBase64Response(base64EncryptedData)
    
    const decryptData = async (decryptData, apiCall = true) => {
      try {
        if(apiCall){
          let data = decryptData
          let  response = await axios.post(`${baseUrl.earth}/decrypt`, { data })
          var { token } = response.data.data
          setData(response.data.data)

          var { name } = helpers.GetProviderConfig()
          setProviderName(name)

        }else{
          let bufferObjj = Buffer.from(decryptData, "base64"); // Create a buffer from the string
          let decodedString = JSON.parse(bufferObjj.toString("utf8")) // Encode the Buffer as a utf8 string

          var { token } = decodedString;
          setData(decodedString)

          if(decodedString.phone_payment){
            setPhonePaymentMode(true)
          }

          var { name } = helpers.GetProviderConfig(decodedString.provider)
          setProviderName(name)
        }

        setToken(token)
        setLoading(false);

      } catch (error) {
        console.log('Error:======>', error)
        const message = error?.response?.data?.error?.message;
        const redirect_url = error?.response?.data?.error?.redirect_url;
        const failedMessage  = error?.response?.data?.error?.failedMessage;
        if (redirect_url) {
          setPaid(true);
          setTimeout(() => { window.location.replace(redirect_url); }, 2000)

        } else {
          setMessage(message)
          setFailure(true)
          setFailedMessage(failedMessage)
        }
      }
    }

    var functionData = encryptedData
    var apiProp = true
    if(base64EncryptedData && base64EncryptedData != "undefined"){
      functionData = base64EncryptedData;
      apiProp = false
    }
    decryptData(functionData, apiProp)
    //for that time you need to show spinner
  }, []);

  useEffect(() => {
    if (!failure) {
      var providerConfig = helpers.GetProviderConfig(data.provider);
      if (decodedData?.cvv_exists) {
        const card_token = decodedToken.token;
        const master_token = decodedToken.master_token;
        const billing_address = decodedData?.avs?.house_number;
        const billing_post_code = decodedData?.avs?.postcode;
        const appendData = {
          data: encryptedData,
          card_token,
          session_id: localStorage?.kSessionId ? localStorage?.kSessionId : '',
          base64Data,
          master_token,
          billing_address,
          billing_post_code,
          deviceInfo: deviceInfo.GetDeviceInfo(),
          cvv_exists: decodedData?.cvv_exists,
        };
        initiateSale(
          appendData,
          setMessage,
          setLoading,
          setFailure,
          setFailedMessage,
          providerConfig.url,
          data
        );
      }
    }
  }, [decodedToken]);

  if (decodedData?.cvv_exists && !loading) {
    return <Spinner message={"Please wait processing payment."} />;
  }

  if (!failure) {
    if (!loading) {
      if (token.length && !exist) {
        return (
          <Container className='col d-flex justify-content-center mt-4'>
            <div className='col-sm-6 col-md-6 col-lg-5 col-xl-4'>
              <div className="btn btn-light back-btn-container" onClick={() => goBackButton(data?.back_url)}>« Back</div>
              <GpayApayContainer data={data} encryptData={encryptedData} base64Data={base64Data} setError={setFailure} setMessage={setMessage} setFailedMessage={setFailedMessage} setLoading={setLoading} />
              <p> Your Saved Credit and Debit Cards</p>
              {renderCard}
              <div className='text-center mt-4'>
                <Link
                  to={{
                    pathname: `/${providerName}/card/?data=${encryptedData}`,
                    state: {
                      data: data,
                      encrypt_data: encryptedData,
                      exist: true,
                      base64_data: base64Data
                    },
                  }}
                  className='nav-link '
                >
                  Add New Card{' '}
                  <p>
                    <i className='fas fa-plus-circle'></i>
                  </p>
                </Link>
              </div>
              <footer className='text-center text-muted footer-size mt-3'>
                Powered by <span className='font-weight-bold'>Datman</span>
              </footer>
            </div>
          </Container>
        )
      } else if (phonePMode) {
        return <PhonePaymentCheckoutForm data={data} encryptData={encryptedData} exist={exist} base64Data={base64Data} />
      } else {
        return <CheckoutForm data={data} encryptData={encryptedData} exist={exist} base64Data={base64Data} />
      }
    } else {
      return <Spinner message={`${!paid && !failure ? 'Please wait' : 'Redirecting to merchant site'}`} />
    }
  } else {
    return (
      <FailurePage
        encryptData={encryptedData}
        message={message}
        cancelUrl={cancelUrl}
        base64Data={base64Data}
        dataDecode={data}
        failedMessage={failedMessage}
      />
    )
  }
}

export default SavedCC


// encrypted data stored in sessionStorage to avoid keep calling it everytime page refreshes
// let encSession = sessionStorage.getItem('encSession')
// encSession = encSession ? JSON.parse(encSession) : null;
// let response;
// if (encSession && encSession.encryptedData === encryptedData) {
//   response = encSession.response;
// } else {
//   response = await axios.post(`${baseUrl.earth}/decrypt`, { data })
//   sessionStorage.setItem('encSession', JSON.stringify({ encryptedData, response }))
// }
