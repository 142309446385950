import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import Spinner from '../../common/Spinner';
import CardList from '../../common/Card/CardList';
import { baseUrl } from '../../../config/baseUrl';
import { getDecodedData } from '../../../helpers';
import { card_types } from '../../../utils/enums';
import SaveCardRedirect from './save-card-redirect';

let BackendUrl = baseUrl.checkout + '/v1/sale/hosted?data=';

function CheckoutHosted() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [token, setToken] = useState([]);
  const [response, setResponse] = useState(null);
  const [decodedData, setDecodedData] = useState({});
  const [via, setVia] = useState(0);

  useEffect(() => {
    let url = window.location.href;
    const params = queryString.parseUrl(url);
    console.log('params: ', params);
    const { data, response, via } = params.query;
    console.log('via', via);
    setData(data);
    setResponse(response);
    setVia(via);

    const decrypData = async () => {
      let { token, decodedString } = await getDecodedData(response);
      setDecodedData(decodedString);
      setToken(token);
    }

    if (via == card_types.NEW_CARD) {
      // redirect directly to new card sale page
      window.location.replace(BackendUrl + data);
    } else if (via == card_types.SAVED_CARD) {
      decrypData();
      console.log('Saving card url: ', window.location.origin);
    }
    setLoading(false);
  }, []);

  const redirectToNewCard = () => {
    let url = BackendUrl + data;
    window.location.replace(url);
  }

  const renderCard = token?.map((_token, i) => {
    return (
      <CardList
        token={_token}
        data={decodedData}
        key={i}
        encryptData={data}
        base64Data={response}
      />
    )
  })
  
  if (loading) {
    return <Spinner message='Please wait redirecting to payment page' />
  } else {
    if (via == card_types.SAVED_CARD) {
      // To complete the checkout process with a saved card, we must first redirect to the saved card payment page and then return to the new card payment page.
      return <SaveCardRedirect data={data} decodedData={decodedData} response={response} redirectToNewCard={redirectToNewCard} renderCard={renderCard}/>
    } else if (via == card_types.NEW_CARD) {
      //To process the checkout new card payment page we will redirect to the new card through the useeffects
      return null;
    }
  }
}

export default CheckoutHosted;
