export const card_types = {
  NEW_CARD: 0,
  SAVED_CARD: 1,
};

export const provider_types = {
  EARTH: 'earth',
};

export const payment_card_type = {
  MASTERCARD: 'mastercard',
  VISA: 'visa',
  AMEX: 'ame',  // to match both amex and american_express
  DINNER_CLUB: 'diners',
  JCB: 'jcb',
  MAESTRO: 'maestro',
};

export const processing_messages = {
  LOADING_MESSAGES: 'Please wait while we process your payment',
};

export const saturn_redirect_method = {
  PAYMENT_INTENT: '"payment-intent"',
  SAVECARD: '"save-card"',
};

export const transaction_status = {
  UNTRIED: 0,
  OK: 1,
  REFUND: 2,
  PAYOUTBATCHED: 3,
  SENTFORPAYOUT: 4,
  PAYOUTSUCCESS: 5,
  PAYOUTFAILED: 6,
  DECLINED: 7,
  RISK_CHECK_DECLINE: 8,
  FAILED: 9,
  ERROR: 10,
  THREEDS_PROGRESS: 11,
};

export const next_action_check_enum = {
  ACTION_REQUIRED: 'requires_action',
  PAYMENT_SUCCESS: 'succeeded',
};

export const paymentStatus = {
  UNTRIED: 'UNTRIED',
  OK: 'OK',
};

export const retry_count = {
  CheckoutRetry: 5
};

export const card_type_names = {
  AMERICAN_EXPRESS: 'american-express',
  DINERS_CLUB: 'diners-club',
  DISCOVER: 'discover'
};

export const post_code_label_types = {
  POST_CODE: 'Postcode',
  ZIP_CODE: 'Zipcode',
};

export const country_iso_code = {
  GB: 'GB',
  IE: 'IE',
  AU: 'AU',
  NZ: 'NZ',
  CA: 'CA',
  VU: 'VU',
  US: 'US',
  GD: 'GD',
  IN: 'IN',
  NG: 'NG',
};

export const payment_providers_names = {
  CARDSTREAM: 'CARDSTREAM',
  OPTOMANY: 'OPTOMANY',
  CARDSTREAM_CH: 'CARDSTREAM-CH',
  CHECKOUT_HF: 'CHECKOUT-HF',
  ADYEN_HF: 'ADYEN-HF',
  SPREEDLY: 'SPREEDLY'
};
