import React, { useState, useEffect } from "react";
import queryString from "query-string";
import Spinner from "../../common/Spinner";
import axios from "axios";
import { baseUrl } from "../../../config/baseUrl";
import { Button} from 'react-bootstrap'
import { goBackButton } from "../../../helpers";
import { retry_count,paymentStatus } from "../../../utils/enums";
export default function Redirect() {
  const [orderId, setOrderId] = useState("");
  const[encryptData,setEncryptedData]=useState("")
  const [loading, setLoading] = useState(true);
  const [failureMessage, setFailureMessage] = useState(false);
  const[base64data,setBase64Data]=useState("");
  useEffect(() => {
    //setting count value so that we can restrict after 5 api call
    let count = 1;
    //setting a delayTimeout in array variable so that each api call can be hit after particular time
    let delayTimeout = [2000, 5000, 8000, 10000, 15000];
    const checkPaymentStatus = async () => {
      setLoading(true);
      try {
        let url = window.location.href;
        const params = queryString.parseUrl(url);
        const { data,payment_data,response } = params.query;

        // Replace 'yourApiUrl' with your actual API endpoint
        const apiUrl = `${baseUrl.checkout}/v1/get-payment-status?data=${data}`;

        // Make an API request to get payment status
        const apiResponse = await axios.get(apiUrl);
        const { payment_status, order_id } = apiResponse.data.paymentData;
        const redirectUrl = apiResponse.data.redirectUrl;
        // Set the order ID based on the API response
        setBase64Data(response)
        setEncryptedData(payment_data)
        setOrderId(order_id);
        /* Below logic checks if the payment status is untried it will hit the api again till we get 
          any other response if we get sucess we will redirect to success url 
          if API return payment status failed it should should error message to user */
        if (payment_status === paymentStatus.OK) {
          // Redirect to a different page when payment is successful
          window.location.replace(redirectUrl);
          setLoading(false);
        } else if (payment_status === paymentStatus.UNTRIED) {
          if (count > retry_count.CheckoutRetry) {
            // If the API has been retried more than 5 times, set a failure state to true
            setFailureMessage(true);
            setLoading(false);
          } else {
            // Retry the API call after particular delayTimeout
            setTimeout(checkPaymentStatus, delayTimeout[count - 1]);
            count++; // Increment the retry count
          }
        } else {
          // If payment status is neither "OK" nor "UNTRIED," set a failure message
          setFailureMessage(true);
          setLoading(false);
        }
      } catch (error) {
        console.error("API request failed", error);
        setFailureMessage(true);
        setLoading(false);
      }
    };

    checkPaymentStatus();
  }, []);

  return loading ? (
    <Spinner message={"Please wait payment in progress."} />
  ) : (
    failureMessage && (
      <div className='container col d-flex justify-content-center mt-4'>
      <div className='col-sm-6 col-md-6 col-lg-5 col-xl-4'>
        {  <div className="btn btn-light back-btn-container" onClick={() => goBackButton(`/checkout/save-card?data=${encryptData}&response=${base64data}`)}>« Back</div> }
        <div className='col-md-12 card'>
        <br></br>
        <b>Payment Failed</b> 
            <p className='lead mt-5'>Your payment for order #{orderId} was not completed.  
  If an amount was debited, it will be refunded to your card within 3 to 5 working days.</p>
            <Button
            className=' btn-block'
            variant='success'
            href={`/checkout/pay/?data=${encryptData}&response=${base64data}=&via=1`}
            data-loading-text='Processing, please wait...'
          >
            Try Again
          </Button>
          <br/>
          </div>
        </div>
      </div>
    )
  );
}
