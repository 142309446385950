import React, { useState, useEffect } from "react";
import axios from "axios";
import valid from "card-validator";
import Cleave from "cleave.js/react";
import { Button, Form, InputGroup, Alert, Card } from "react-bootstrap";

import { baseUrl } from "../../config/baseUrl";
import { goBackButton, getDecodedData } from "../../helpers";
import Spinner from "../common/Spinner";
import "./style.css";

const AMERICAN_EXPRESS = "american-express";
const DINERS_CLUB = "diners-club";
const DISCOVER = "discover";

export default function AddCardContainer({ encryptedData, base64Data }) {

  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [backUrl, setBackUrl] = useState("");
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  const [unsupportedCardTypes, setUnsupportedCardTypes] = useState({
    amex: false,
    discover: false,
    diners: false
  });

  const [cardDetails, setCardDetails] = useState({
    cardNumber: "",
    expDate: "",
    cvv: ""
  });

  const [cardFormErrors, setCardFormErrors] = useState({
    cardError: true,
    cvvError: true,
    expDateError: true
  });

  const [cardFieldLengths, setCardFieldLengths] = useState({
    cardNumber: 16,
    cvv: 3
  });

  const [billingAddressData, setBillingAddressData] = useState({
    address: "",
    postCode: "",
    isBillingAddressRequired: false
  });

  const [messages, setMessages] = useState({
    isOflinemessage: "",
    notAvialbaleAddCard: "",
    errorDecryptPayload: "",
    userSuccessMessage: "",
    errorUserMessage: ""
  });

  useEffect(() => {
    const decryptData = async (base64Data) => {
      const { decodedString } = await getDecodedData(base64Data);
      setBackUrl(decodedString?.backurl);
      setBillingAddressData(prevState => ({
        ...prevState,
        isBillingAddressRequired: decodedString?.countryCode?.toUpperCase() === 'GB' || decodedString?.countryCode?.toUpperCase() === 'IE'
      }));
    };
    base64Data && decryptData(base64Data);
  }, [base64Data]);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const handleCardSubmission = async (e) => {
    e.preventDefault();
    if (!isOnline) {
      setMessages(prevState => ({
        ...prevState,
        isOflinemessage: "Please connect your device to the internet.",
        errorUserMessage: ""
      }));
      return;
    }

    setLoading(true);
    const cardInfoData = {
      encrypted_data: encryptedData,
      card_number: cardDetails.cardNumber,
      expiry_month: cardDetails.expDate.slice(0, 2),
      expiry_year: cardDetails.expDate.slice(2),
      cvv: cardDetails.cvv,
      billing_address: billingAddressData.address,
      billing_post_code: billingAddressData.postCode
    };
    await submitCardData(cardInfoData);
  };

  const submitCardData = async (data) => {
    try {
      let response = await axios.post(`${baseUrl.restApi}/add-card/create`, { ...data });
      if (response.status === 200 && response?.data?.body?.message) {
        let redirectUrl = decodeURIComponent(response?.data?.headers?.location);
        setMessages(prevState => ({
          ...prevState,
          userSuccessMessage: response?.data?.body?.message
        }));
        window.setTimeout(function () {
          window.location.replace(redirectUrl);
        }, 2000);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        setMessages(prevState => ({
          ...prevState,
          errorUserMessage: error?.response?.data?.error?.message
        }));
      } else {
        setMessages(prevState => ({
          ...prevState,
          errorUserMessage: "Unable to process your request."
        }));
      }
    }
  };

  const validateNumericInput = (value) => {
    const re = /^[0-9\b]+$/;
    return value === "" || re.test(value);
  };

  useEffect(() => {
    const fetchDecryptedData = async (data) => {
      try {
        const url = `${baseUrl.restApi}/add-card/validate`;
        const decryptedResponsePayload = await axios.post(url, { data: data });
        if (decryptedResponsePayload.status === 200 && decryptedResponsePayload.data && decryptedResponsePayload.data.status === 'failed') {
          setMessages(prevState => ({
            ...prevState,
            notAvialbaleAddCard: decryptedResponsePayload.data.body.message
          }));
        }
      } catch (error) {
        setMessages(prevState => ({
          ...prevState,
          errorDecryptPayload: error?.response?.data?.error?.message
        }));
      }
    };
    encryptedData && fetchDecryptedData(encryptedData);
  }, [encryptedData]);

  useEffect(() => {
    let numberValidation = valid.number(cardDetails.cardNumber);
    let result =
      !cardFormErrors.cardError ||
      !cardFormErrors.cvvError ||
      !cardFormErrors.expDateError ||
      (billingAddressData.isBillingAddressRequired && !billingAddressData.address.length) ||
      (billingAddressData.isBillingAddressRequired && !billingAddressData.postCode.length) ||
      !numberValidation?.isValid ||
      numberValidation?.card?.code?.size !== cardDetails.cvv.length ||
      cardDetails.expDate.length !== 4;
    setIsDisabled(result);
  }, [
    cardFormErrors.cardError,
    cardFormErrors.cvvError,
    cardFormErrors.expDateError,
    cardDetails.cardNumber,
    cardDetails.expDate,
    cardDetails.cvv,
    billingAddressData.address,
    billingAddressData.postCode,
    billingAddressData.isBillingAddressRequired
  ]);

  return messages.notAvialbaleAddCard ? (
    <div>
      <div className="text-center border-danger add-card-container">
        <div className="back-btn-container">
          <div className="btn btn-light" onClick={() => goBackButton(backUrl)}>
            « Back
          </div>
        </div>
      </div>
      <Card className="text-center border-primary add-card-container alert-info">
        <Card.Body>
          <div className="text-secondary" style={{ fontSize: "18px" }}>
            {messages.notAvialbaleAddCard}
          </div>
        </Card.Body>
      </Card>
    </div>
  ) : messages.errorDecryptPayload ? (
    <div>
      <div className="text-center border-danger add-card-container">
        <div className="back-btn-container">
          <div className="btn btn-light" onClick={() => goBackButton(backUrl)}>
            « Back
          </div>
        </div>
      </div>
      <Card className="text-center border-danger add-card-container alert-danger">
        <Card.Body>
          <div className="text-danger" style={{ fontSize: "18px" }}>
            {messages.errorDecryptPayload}
          </div>
        </Card.Body>
      </Card>
    </div>
  ) : !loading ? (
    <div className="container col d-flex justify-content-center">
      <div className="col-sm-6 col-md-6 col-lg-5 col-xl-4 mt-4">
        <div className="back-btn-container">
          <div className="btn btn-light" onClick={() => goBackButton(backUrl)}>
            « Back
          </div>
        </div>
        {messages.userSuccessMessage ? (
          <Alert variant="success" className="alert-box">
            <Alert.Heading>Success</Alert.Heading>
            {messages.userSuccessMessage}
          </Alert>
        ) : (
          <Form onSubmit={handleCardSubmission} method="POST">
            {messages.isOflinemessage && !isOnline && <Alert variant="info">
              {messages.isOflinemessage}
            </Alert>}
            {messages.errorUserMessage && (
              <Alert variant="danger" className="alert-box">
                {messages.errorUserMessage}
              </Alert>
            )}
            <Alert variant="info" show={unsupportedCardTypes.amex || unsupportedCardTypes.discover || unsupportedCardTypes.diners}>
              Sorry, we don’t accept{" "}
              {unsupportedCardTypes.amex
                ? "AMEX"
                : unsupportedCardTypes.discover
                  ? "Discover"
                  : unsupportedCardTypes.diners
                    ? "Diners Club"
                    : ""}{" "}
              cards.
            </Alert>
            <Form.Group>
              <Form.Label className="">Card Information</Form.Label>
              <InputGroup>
                <Cleave
                  autoFocus
                  options={{ creditCard: true }}
                  type="tel"
                  name="cardnumber"
                  autoComplete="cc-number"
                  placeholder="1234 1234 1234 1234"
                  className={` form-control  ${!cardFormErrors.cardError ? "error-color" : ""} `}
                  style={{ borderBottom: "0", borderBottomLeftRadius: "0", }}
                  required
                  minLength={cardFieldLengths.cardNumber}
                  value={cardDetails.cardNumber}
                  onBlur={() => {
                    let isValidCard;
                    if (cardDetails.cardNumber) {
                      isValidCard = valid.number(cardDetails.cardNumber, { maxLength: 16, });
                      setCardFormErrors(prevState => ({
                        ...prevState,
                        cardError: isValidCard.isValid
                      }));
                      if (
                        isValidCard !== undefined &&
                        isValidCard !== null &&
                        isValidCard.card !== null
                      ) {
                        if (isValidCard.card.type === AMERICAN_EXPRESS) {
                          setUnsupportedCardTypes(prevState => ({
                            ...prevState,
                            amex: true
                          }));
                          setCardDetails(prevState => ({
                            ...prevState,
                            cardNumber: ""
                          }));
                          setCardFormErrors(prevState => ({
                            ...prevState,
                            cardError: true
                          }));
                          setMessages(prevState => ({
                            ...prevState,
                            isOflinemessage: ""
                          }));
                          return;
                        } else if (isValidCard.card.type === DINERS_CLUB) {
                          setUnsupportedCardTypes(prevState => ({
                            ...prevState,
                            diners: true
                          }));
                          setCardDetails(prevState => ({
                            ...prevState,
                            cardNumber: ""
                          }));
                          setCardFormErrors(prevState => ({
                            ...prevState,
                            cardError: true
                          }));
                          setMessages(prevState => ({
                            ...prevState,
                            isOflinemessage: ""
                          }));
                          return;
                        } else if (isValidCard.card.type === DISCOVER) {
                          setUnsupportedCardTypes(prevState => ({
                            ...prevState,
                            discover: true
                          }));
                          setCardDetails(prevState => ({
                            ...prevState,
                            cardNumber: ""
                          }));
                          setCardFormErrors(prevState => ({
                            ...prevState,
                            cardError: true
                          }));
                          setMessages(prevState => ({
                            ...prevState,
                            isOflinemessage: ""
                          }));
                          return;
                        }
                        if (isValidCard.card.code.size !== null) {
                          setCardFieldLengths(prevState => ({
                            ...prevState,
                            cvv: isValidCard.card.code.size
                          }));
                          setCardFieldLengths(prevState => ({
                            ...prevState,
                            cardNumber: isValidCard.card.lengths[0]
                          }));
                        }
                      }
                    } else {
                      setCardFormErrors(prevState => ({
                        ...prevState,
                        cardError: true
                      }));
                    }
                  }}
                  onChange={(event) => {
                    setUnsupportedCardTypes(prevState => ({
                      ...prevState,
                      amex: false
                    }));
                    setUnsupportedCardTypes(prevState => ({
                      ...prevState,
                      discover: false
                    }));
                    setUnsupportedCardTypes(prevState => ({
                      ...prevState,
                      diners: false
                    }));
                    const value = event.target.rawValue;
                    const re = /^[1-9][0-9]*$/;
                    if (value === "" || re.test(value)) {
                      setCardDetails(prevState => ({
                        ...prevState,
                        cardNumber: value
                      }));
                    }
                  }}
                />
                <InputGroup>
                  <Cleave
                    options={{ date: true, datePattern: ["m", "y"] }}
                    placeholder="MM YY"
                    name="cc-exp"
                    style={{ borderTopLeftRadius: "0" }}
                    className={`shadow-sm form-control ${!cardFormErrors.expDateError ? "error-color" : ""} `}
                    pattern="[0-9/]*"
                    type="tel"
                    autoComplete="cc-exp"
                    value={cardDetails.expDate}
                    minLength="5"
                    maxLength="5"
                    required
                    onChange={(event) => {
                      const value = event.target.rawValue;
                      if (validateNumericInput(value)) {
                        setCardDetails(prevState => ({
                          ...prevState,
                          expDate: value
                        }));
                      }
                    }}
                    onBlur={() => {
                      let isDateValid;
                      if (cardDetails.expDate) {
                        isDateValid = valid.expirationDate(cardDetails.expDate).isValid;
                        setCardFormErrors(prevState => ({
                          ...prevState,
                          expDateError: isDateValid
                        }));
                      }
                    }}
                  />
                  <Form.Control
                    type="tel"
                    name="cvc"
                    autoComplete="cc-csc"
                    placeholder="CVV"
                    className={`shadow-sm col-md-9 form-control ${!cardFormErrors.cvvError ? "error-color" : ""} `}
                    style={{ borderTopRightRadius: "0" }}
                    pattern="[0-9]*"
                    value={cardDetails.cvv}
                    minLength={cardFieldLengths.cvv}
                    maxLength={cardFieldLengths.cvv}
                    required
                    onChange={(event) => {
                      const value = event.target.value;
                      if (validateNumericInput(value)) {
                        setCardDetails(prevState => ({
                          ...prevState,
                          cvv: value
                        }));
                      }
                    }}
                    onBlur={() => {
                      let isValidCVV;
                      if (cardDetails.cvv) {
                        isValidCVV = valid.cvv(cardDetails.cvv, cardFieldLengths.cvv).isValid;
                        setCardFormErrors(prevState => ({
                          ...prevState,
                          cvvError: isValidCVV
                        }));
                      }
                    }}
                  />
                </InputGroup>
              </InputGroup>
            </Form.Group>
            <Form.Group>
              {billingAddressData.isBillingAddressRequired &&
                <Form.Group>
                  <Form.Label className="">Billing Address</Form.Label>
                  <InputGroup>
                    <Form.Control
                      placeholder="Address Line 1"
                      name="house-number"
                      value={billingAddressData.address}
                      style={{ borderBottomLeftRadius: "0", borderBottom: "0", borderTopRightRadius: "0.25em" }}
                      className={` form-control`}
                      onChange={(event) => {
                        const value = event.target.value;
                        setBillingAddressData(prevState => ({
                          ...prevState,
                          address: value
                        }));
                      }}
                    />
                    <InputGroup>
                      <Form.Control
                        placeholder="Postcode"
                        name="post-code"
                        value={billingAddressData.postCode}
                        style={{ borderTopLeftRadius: "0", borderBottomRightRadius: "0.25em", borderTopRightRadius: "0", width: "50%" }}
                        className={`shadow-sm form-control`}
                        maxLength="8"
                        onChange={(event) => {
                          const value = event.target.value.toUpperCase();
                          setBillingAddressData(prevState => ({
                            ...prevState,
                            postCode: value
                          }));
                        }}
                      />
                    </InputGroup>
                  </InputGroup>
                </Form.Group>
              }
            </Form.Group>
            <Form.Group>
              <Button variant="primary" block type="submit" disabled={isDisabled}>Add Card</Button>
            </Form.Group>
            <Form.Group>
              <p className="text-muted text-center">
                <small>
                  <i className="fas fa-lock"></i> Your card information is encrypted
                </small>
              </p>
            </Form.Group>
          </Form>
        )}
      </div>
    </div>
  ) : (
    <Spinner message="Please wait while we are adding your card details..." />
  );
}