import React, { useState, useEffect, isValidElement } from 'react';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import Spinner from '../../common/Spinner';
import './styles.css';
import { buttonTxts } from '../../../helpers/error-messages-enum';

const ErrorMessage = ({ encryptData, failedMessage, message ,cancleUrl}) => {
  const [show, setShow] = useState(false);

  const handleRefreshClick = () => {
    window.location.reload();
  };
  const handleCancelClick=()=>{
    window.location.replace(cancleUrl)
  }

  console.log(isValidElement(cancleUrl));
  return show ? (
    <Spinner message="Processing, please wait..." />
  ) : (
    <div className="innerContainer">
      <br></br>
      <p>{failedMessage}</p>
      <p className="errMsg">{message}</p>
      <Button id="tryAgainBtn" onClick={handleRefreshClick} >{buttonTxts.TRY_AGAIN}</Button>
      {cancleUrl && <Button id="cancelBtn" onClick={handleCancelClick}>{buttonTxts.CANCEL}</Button>}
    </div>
  );
};

export default ErrorMessage;
