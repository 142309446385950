import React, { useState, useEffect } from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import images from './images';
import { helpers } from '../../../helpers';
import { provider_types, payment_card_type } from '../../../utils/enums';

const CardList = ({ token, data, encryptData, base64Data, provider }) => {
  const [cardImage, setCardImage] = useState(images.placeholder);
  // const [DeleteCard, setdeleteCard] = useState('here');
  const [loading, setLoading] = useState(false);
  // const [isDeleted, setIsDeleted] = useState(false);
  const [providerName, setProviderName] = useState("");

  const cardScheme = token.card_scheme?.toLowerCase();
  useEffect(() => {
    if (cardScheme?.includes(payment_card_type.MASTERCARD)) {
      setCardImage(images.mastercard);
    } else if (cardScheme?.includes(payment_card_type.VISA)) {
      setCardImage(images.visa);
    } else if (cardScheme?.includes(payment_card_type.AMEX)) {
      setCardImage(images.amex);
    } else if (cardScheme?.includes(payment_card_type.DINNER_CLUB)) {
      setCardImage(images.dinersclub);
    } else if (cardScheme?.includes(payment_card_type.JCB)) {
      setCardImage(images.jcb);
    } else if (cardScheme?.includes(payment_card_type.MAESTRO)) {
      setCardImage(images.maestro);
    } else {
      setCardImage(images.placeholder);
    }

    var { name } = helpers.GetProviderConfig(data.provider)
    setProviderName(name)

  })

  const redirectToSavedCard = (provider) => {
    let url = `${window.location.origin}/checkout/save-card?data=${encryptData}&response=${base64Data}`;
    if (provider === provider_types.EARTH) {
      window.location.replace('#');
    } else {
      window.location.replace(url);
    }
  }

  return (

    <Card className='mb-2'>
      <Link to={provider === provider_types.EARTH ? {
        pathname: `/${providerName}/pay`,
        state: { data, token, encryptData, base64Data },
      } : "#"} className="nav-link" onClick={() => redirectToSavedCard(provider)}>

        <Card.Body className='m-1'>
          <img style={{
            height: '1.5em',
            width: '2em'
          }} src={`${cardImage || images.placeholder}`}>
          </img>&nbsp;&nbsp;**** **** **** {token.last_four_digits}
        </Card.Body>
      </Link>

    </Card>
  )
}

export default CardList
